import { Controller } from "@hotwired/stimulus"
import InvoiceFormController from "./invoice_form_controller"
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ["screen", "searchInput", "screenHuman", "pricePress", "bookSeries", "bookNum"]
  static values = {
    url: String,
    accomondation: Boolean,
    cateringOrderNote: Boolean,
    keystrokesEnabled: { type: Boolean, default: true },
    quantityHuman: String,
    pricePress: { type: Boolean, default: false },
    defaultPos: String,
    discountAmount: Number,
    discountType: String

  }

  initialize() {
    window.addEventListener('keydown', (e) => {
      if (this.allowedKeys().includes(e.key) && this.keystrokesEnabledValue) {
        this.keyBoardListener(e.key)

      }
    })
    this.watchForItems();
    this.watchForModal();

  }

  connect() {
    this.fetchProducts();
  }

  billingBookChanged(e) {
    this.setBillingBookData(e.target.value)
  }

  setBillingBookData(id) {
    const elem = document.querySelector(`.js-billing-book-data-${id}`)
    if (elem) {
      const data = JSON.parse(elem.dataset.value)
      this.bookSeriesTarget.value = data["series"]
      this.bookNumTarget.value = data["num"]
    }
  }

  allowedKeys() {
    return ['Backspace', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '*'];
  }

  keyBoardListener(key) {

    if (key == 'Backspace') {
      this.numPress({ params: { num: '<-' } })

    } else {
      this.numPress({ params: { num: key } })
    }
  }

  setDiscount(e) {
    var [amount, type] = [e.detail.amount, e.detail.type]
    this.discountTypeValue = type
    this.discountAmountValue = amount

  }

  toggleNumPad(e) {
    this.keystrokesEnabledValue = e.detail.numPadStatus
  }

  // if modal is open disable keystrokes so they don't register on cash register's numpad
  watchForModal() {
    $('.modal').on('shown.bs.modal', (e) => {
      this.keystrokesEnabledValue = false
    })
    $('.modal').on("hidden.bs.modal", () => {
      this.keystrokesEnabledValue = true
    })
  }

  watchForItems() {
    $('#nested-rows').on('cocoon:after-remove', () => { this.validatePayButton() });
    $('#nested-rows').on('cocoon:after-insert', () => { this.validatePayButton() });
  }

  validatePayButton() {
    const draftButton = document.getElementById('draftButton')
    const payButton = document.getElementById('payButton')
    const enabled = document.getElementById('nested-rows').children.length > 0
    if (enabled) {
      payButton.classList.remove('disabled')
      if (draftButton) draftButton.classList.remove('disabled')
    } else {
      if (!payButton.classList.contains('disabled')) {
        payButton.classList.add('disabled')
      }
      if (draftButton && !draftButton.classList.contains('disabled')) {
        payButton.classList.add('disabled')
      }
    }
  }


  numPress({ params: { num } }) {
    switch (num) {
      case '<<-':
        this.nullifyScreen()
        break;
      case '<-':
        this.screenTarget.innerHTML = this.screenTarget.innerHTML.slice(0, -1);
        break;
      case '.':
        if (!this.screenTarget.innerHTML.split('*').reduce((p, i) => { return p && i.includes('.') }, true)) {
          if (this.screenTarget.innerHTML.slice(-1) != '*') {
            this.screenTarget.innerHTML += num
          }
        }
        break;
      case '*':
        if ((this.screenTarget.innerHTML.length > 1 && !this.screenTarget.innerHTML.includes('*')) || this.screenTarget.innerHTML != '0') {
          this.screenTarget.innerHTML += num
        }
        break;
      default:
        this.screenTarget.innerHTML += num
        break;
    }
    if (this.screenTarget.innerHTML.slice(0, 1) === '0' && this.screenTarget.innerHTML.slice(0, 2) !== '0.') {
      this.screenTarget.innerHTML = this.screenTarget.innerHTML.slice(1);
    }
    if (this.screenTarget.innerHTML === '') {
      this.nullifyScreen()
    }
    this.humanizeScreen()

  }
  nullifyScreen() {
    this.screenTarget.innerHTML = '0';
    this.screenHumanTarget.innerHTML = '0';
  }

  humanizeScreen() {
    var result, price, quantity, realQuantity
    if (this.pricePressValue) {
      [quantity] = this.quantityPrice()
      result = `${quantity} ${this.quantityHumanValue} X `
    } else {
      [price, quantity, realQuantity] = this.quantityPrice()
      if (price != 0) {
        result = `${price} €`
        if (this.screenTarget.innerHTML.includes('*') && realQuantity) {
          result += ` <icon class='bi fs-3 bi-x'></icon> `
          result += `${quantity} ${this.quantityHumanValue}`
        }

      }
    }
    result ||= 0
    this.screenHumanTarget.innerHTML = result
  }
  pricePress() {
    this.pricePressValue = this.pricePressTarget.checked
    document.querySelectorAll('.no-product-price-badge').forEach((badge) => {
      badge.hidden = !this.pricePressValue
    })
    document.querySelectorAll('.no-product-price').forEach((button) => {
      if (this.pricePressValue) {
        button.classList.add('disabled')
      } else {
        button.classList.remove('disabled')
      }
    })

  }

  vatPress({ params: { product, vat } }) {
    product.vat_rate = vat
    this.productPress({ params: { product } })
  }

  isBlank(value) {
    return [null, undefined, ''].includes(value)
  }

  productPress({ params: { product } }) {
    // when pricePressValue is true the expected behaviour is for the user to press a number
    // and then press a product in order to insert the product price and quantity or just press a product
    // Due to this the price and quantity stored in the screenTarget are reversed in regards to the normal behaviour
    if (this.isBlank(product.vat_rate) || (product.vat_rate == 0 && this.isBlank(product.vat_exemption_code))) {
      document.querySelectorAll('.vatValues').forEach((vat) => {
        vat.setAttribute('data-cash-register-product-param', JSON.stringify(product))
      })
      $('#vatModal').modal('show')
      return;
    }
    if (this.pricePressValue) {
      var currentVal = this.screenTarget.innerHTML.replace('*', '')
      currentVal ||= 1
      currentVal = currentVal == '0' ? 1 : currentVal
      this.screenTarget.innerHTML = `${product.price}*${currentVal || 1}`
    }
    if (this.accomondationValue) {
      this.setAccomondationLine(product);
    } else {
      this.setRegularLine(product);
    }
  }
  setAccomondationLine(product) {
    var [name, code, amount] = product.split('|');
    var quantity = parseInt(this.quantityPrice()[0]);
    var total = quantity * amount;


    document.getElementById('invoice-line-add-button').click();
    const row = document.getElementById('nested-rows').lastElementChild;


    row.querySelector('.num_value').value = document.getElementById('nested-rows').children.length;
    row.querySelector('.other_taxes_percent_category_value').value = code;
    row.querySelector('.accomondation_tax_value').value = amount;
    row.querySelector('.other_taxes_amount_value').value = total;

    row.getElementsByClassName('name')[0].innerHTML = name;
    row.getElementsByClassName('quantity')[0].innerHTML = quantity;
    row.getElementsByClassName('accomondation_tax')[0].innerHTML = amount;
    row.getElementsByClassName('other_taxes_amount')[0].innerHTML = total;
    this.screenTarget.innerHTML = '0';
  }

  setRegularLine({ name, vat_rate, quantity_type, id, price, vat_exemption_code }) {
    var [price, quantity] = this.quantityPrice()
    var vat = Number(vat_rate)
    var quantity_type = quantity_type
    var [subtotal, unit_price, netTotal] = this.getAmounts(vat);


    document.getElementById('invoice-line-add-button').click();
    const row = document.getElementById('nested-rows').lastElementChild;


    row.querySelector('.num_value').value = document.getElementById('nested-rows').children.length;
    row.querySelector('.name_value').value = name;
    row.querySelector('.unit_price_value').value = price;
    row.querySelector('.net_total_value').value = netTotal;
    row.querySelector('.subtotal_value').value = subtotal;
    row.querySelector('.vat_rate_value').value = vat;
    row.querySelector('.quantity_value').value = quantity;
    row.querySelector('.service_line_id').value = id;
    row.querySelector('.vat_exemption_code').value = vat_exemption_code;
    if (this.discountAmountValue && this.discountAmountValue > 0) {
      var discountText = this.discountAmountValue
      row.querySelector('.js-discount-type').value = this.discountTypeValue;
      if (this.discountTypeValue == "amount") {
        row.querySelector('.js-discount-amount').value = this.discountAmountValue;
        discountText += '€'
      } else {
        row.querySelector('.js-discount-percentage').value = this.discountAmountValue;
        discountText += '%'
      }
    }

    row.getElementsByClassName('name')[0].innerHTML = name;
    row.getElementsByClassName('unit_price')[0].innerHTML = unit_price;
    row.getElementsByClassName('discount')[0].innerHTML = discountText || '-';
    row.getElementsByClassName('quantity')[0].innerHTML = quantity;
    if (row.getElementsByClassName('vat_rate')[0]) row.getElementsByClassName('vat_rate')[0].innerHTML = vat;
    if (row.getElementsByClassName('quantity_type')[0]) row.getElementsByClassName('quantity_type')[0].innerHTML = quantity_type;
    row.getElementsByClassName('sub_total')[0].innerHTML = subtotal;
    this.nullifyScreen()

  }
  quantityPrice() {
    var [price, quantity] = this.screenTarget.innerHTML.split('*')
    var realQuantity = quantity
    quantity ||= 1
    quantity = Number(quantity).toFixed(2)
    price = Number(price).toFixed(2)
    return [price, quantity, realQuantity]
  }

  getAmounts(vat) {
    let [price, quantity] = this.quantityPrice()

    // discount
    if (this.discountAmountValue && this.discountAmountValue > 0) {
      price = price / (1 + (vat / 100))
      if (this.discountTypeValue == 'percentage') {
        price = price - (price * this.discountAmountValue / 100)
      } else if (this.discountTypeValue == 'amount') {
        price = price - this.discountAmountValue
      }
      price = price + (price * vat / 100)
    }

    let i = new InvoiceFormController();
    var [subTotal, vatTotal, netTotal, withholdingTotal] = i.calculateLineValues(
      Number(price),
      Number(quantity),
      Number(vat),
      0,
      true
    )
    return [Number(subTotal).toFixed(2), (Number(subTotal).toFixed(2) / quantity).toFixed(2), Number(netTotal).toFixed(2)]
  }

  valueClassNames() {
    if (this.accomondationValue) {
      return [
        { className: '.name', col: 'col-5' },
        { className: '.quantity', col: 'col-2' },
        { className: '.accomondation_tax', col: 'col-2' },
        { className: '.other_taxes_amount', col: 'col-3' }
      ]
    } else if (this.cateringOrderNoteValue) {
      return [
        { className: '.name', col: 'col-4' },
        { className: '.quantity', col: 'col-2', text_align: 'text-center' },
        { className: '.quantity_type', col: 'col-2', text_align: 'text-center' },
        { className: '.vat_rate', col: 'col-2', text_align: 'text-center' },
        { className: '.sub_total', col: 'col-2', text_align: 'text-end' }
      ]
    } else {
      return [
        { className: '.name', col: 'col-3' },
        { className: '.quantity', col: 'col-2' },
        { className: '.vat_rate', col: 'col-2' },
        { className: '.discount', col: 'col-2' },
        { className: '.sub_total', col: 'col-3' }
      ]
    }
  }

  totalClassName() {
    if (this.accomondationValue) {
      return '.other_taxes_amount'
    } else {
      return '.sub_total'
    }
  }

  createFinal() {
    let valueClassNames = this.valueClassNames()
    var nameDiv, textVal, grandTotal = 0
    let finalRows = document.querySelector('.final_items')
    finalRows.innerHTML = ''
    let rows = [...document.getElementById('nested-rows').children]
    rows.forEach((row) => {
      valueClassNames.forEach((val) => {
        textVal = row.querySelector(val.className).innerHTML
        if (val.className == '.vat_rate') {
          textVal += '%'
        }
        nameDiv = document.createElement("div");
        nameDiv.classList.add(val.col, val.text_align)
        nameDiv.innerHTML = textVal
        finalRows.append(nameDiv)
        if (val.className == this.totalClassName()) {
          grandTotal += Number(textVal)
        }
      })
    })
    document.querySelector('.grandTotal').innerHTML = grandTotal.toFixed(2);
  }

  searchProducts() {
    this.debounce(this.fetchProducts(), 300)
  }

  fetchProducts() {
    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      dataType: "json",
      data: `search=${this.searchInputTarget.value}&products_card=true`,
      success: (result) => {
        Rails.$("#productsIndex")[0].innerHTML = result.html;
      }
    })

  }

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

}
