import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import * as ActionCable from '@rails/actioncable'
import Rails from "@rails/ujs"
import GenericController from "./generic_controller"

export default class extends Controller {
  static targets = ["posOptions", "posSelection"]
  static values = {
    defaultPos: String,
    allowedPosPayments: Boolean,
    idsToShowOnPaymentProcess: Array,
    idsToHideOnPaymentProcess: Array,
    abortSessionPath: String,
    session: Object,
    checkoutPath: String,
    formId: String,
    paymentSucceded: Boolean,
    invoiceSubmitPath: String,
    cancellingMessage: String,
    issueMethod: { type: String, default: "issue" },
  }

  initialize() {
    window.App || (window.App = {});
    window.App.cable = ActionCable.createConsumer();
    this.setPaymentProcess(false)
    this.genericController = new GenericController();
  }
  connect() {
    this.handlePaymentMethod()
  }

  // Used to abort a pos session
  abortSession() {
    this.genericController.pageLoad(this.cancellingMessageValue)
    Rails.ajax({
      type: "POST",
      url: this.abortSessionPathValue,
      dataType: "json",
      data: new URLSearchParams({ id: this.sessionValue.id }).toString(),
      success: (result) => {
        this.setPaymentProcess(false)
        this.resetCountdownPaymentBar()
        this.closeInvoicesModal()

      }
    })
  }


  setFormToUpdate(invoiceId) {
    document.getElementById(this.formIdValue).action = this.invoiceSubmitPathValue + '/' + invoiceId
    var i = document.createElement('input')
    i.type = 'hidden'
    i.name = '_method'
    i.value = 'patch'
    document.getElementById(this.formIdValue).appendChild(i)
  }



  // Main method used for handling pos payments. Subscribes to a channel and updates ui based on the 
  // messages received from the channel
  posPay(event) {
    this.paymentSuccededValue = false
    let controller = this;
    var error_message_div = document.getElementById("transaction_message")
    error_message_div.innerHTML = ''
    var formData = new URLSearchParams(new FormData(document.querySelector('#' + this.formIdValue))).toString()
    controller.resetCountdownPaymentBar()
    Rails.ajax({
      url: this.checkoutPathValue,
      type: 'POST',
      data: formData,
      success: (response) => {
        if (response.status && response.status == 'pending_payment') {
          error_message_div.innerHTML = response.message
          controller.paymentInProcess()
          document.getElementById('abortSessionBtn').classList.add('d-none')
          controller.abortSubscription = App.cable.subscriptions.create({ channel: "PosPaymentsChannel", id: response.session_id }, {
            connected() {
              controller.sessionValue = { id: response.session_id }
              controller.abortSession()
            },

            received(data) {
              if (data.session.status == 'cancelled_by_merchant') {
                App.cable.disconnect()
                App.cable.subscriptions.remove(controller.abortSubscription);
                controller.posPay(event)
                controller.paymentInProcess()
                controller.genericController.closePageLoader()
              }
            }
          })
        } else {
          controller.sessionValue = response.session
          document.getElementById('abortSessionBtn').classList.remove('d-none')
          controller.openInvoicesModal()
          App.cable.subscriptions.create({ channel: "PosPaymentsChannel", id: response.session.id }, {
            received(data) {
              controller.sessionValue = data.session
              if (data.session.status == 'success') {
                var url = controller.invoiceSubmitPathValue + '/' + data.session.invoice_id
                if (controller.issueMethodValue == 'issue_and_print') {
                  url += '/thermal_print'
                }
                if (window.location.href.indexOf('cash_register') > -1) {
                  url += '?from_cash_register=true'
                }
                window.location.assign(url)
              } else {
                controller.genericController.closePageLoader()
                controller.setPaymentProcess(false)
                error_message_div.innerHTML = data.message
                controller.resetCountdownPaymentBar()
                controller.closeInvoicesModal()
              }
            }
          });
        }
      },
      error: (response) => {
        error_message_div.innerHTML = response.error
        controller.setPaymentProcess(false)
        controller.resetCountdownPaymentBar()
        controller.closeInvoicesModal()
      }
    });
    return false;

  }

  // used only for payments in invoices form
  closeInvoicesModal() {
    var modalEl = document.getElementById("sending")
    if (modalEl) {
      const modal = bootstrap.Modal.getInstance(modalEl)
      modal.hide()
      document.getElementById("transaction_message").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }
  }

  openInvoicesModal() {
    var modalEl = document.getElementById("sending")
    if (modalEl) {
      const modal = new bootstrap.Modal(modalEl)
      modal.show()
    }
  }

  posPayWithPrint() {
    this.issueMethodValue = 'issue_and_print'
    this.posPay()
  }

  posPayNoPrint() {
    this.issueMethodValue = 'issue'
    this.posPay()
  }


  // if pos payment is succefull show the form buttons and hide the pos ones as the form may return errors
  setPayButtons(paymentIsPos) {
    let hiddenButtons = []
    let shownButtons = []
    if (paymentIsPos) {
      hiddenButtons = ['formSbmt', 'formSbmtWithPrint']
      shownButtons = ['posPay', 'posPayWithPrint']
    } else {
      hiddenButtons = ['posPay', 'posPayWithPrint']
      shownButtons = ['formSbmt', 'formSbmtWithPrint']
    }

    hiddenButtons.forEach((id) => {
      document.getElementById(id)?.classList?.add('d-none')
    })
    shownButtons.forEach((id) => {
      document.getElementById(id)?.classList?.remove('d-none')
    })
  }

  // based on whether pos payment method is rendered in select2 or not
  // if not then the select div should have a class of payment-radio
  paymentMethodVal() {
    if (document.querySelector('.payment-radio')) {
      return document.querySelector('.payment-radio:checked').value
    } else {
      const paymentMethod = $('#paymentMethod')
      return paymentMethod.find(':selected')[0].value
    }
  }

  handlePaymentMethod() {
    if (this.allowedPosPaymentsValue && this.hasPosSelectionTarget && this.paymentMethodVal() == 'card') {
      this.posOptionsTarget.classList.remove('d-none')
      this.posSelectionTarget.value = this.defaultPosValue
      this.setPayButtons(true)
    } else {
      if (this.hasPosOptionsTarget) {
        this.posOptionsTarget.classList.add('d-none')
      }
      if (this.hasPosSelectionTarget) {
        this.posSelectionTarget.value = null
      }
      this.setPayButtons(false)
    }
  }

  paymentInProcess() {
    if (this.hasPosSelectionTarget && this.posSelectionTarget.value) {
      this.updateCountdownPaymentBar();
      if (document.getElementById('modalXBtn')) {
        document.getElementById('modalXBtn').disabled = true
      }
      this.setPaymentProcess(true)
    }
  };

  resetCountdownPaymentBar() {
    const div = document.getElementById("countdownPaymentBar");
    div.setAttribute('aria-valuenow', 65)
    div.style.width = '100%'
  }

  updateCountdownPaymentBar() {
    const div = document.getElementById("countdownPaymentBar");
    const val = Number(div.getAttribute('aria-valuenow'))
    if (val > 0) {
      div.setAttribute('aria-valuenow', val - 1)
      const max = div.getAttribute('aria-maxvalue', val)
      div.style.width = String(((val - 1) / max) * 100) + '%';
      setTimeout(() => {
        this.updateCountdownPaymentBar();
      }, 1000);
    }
  }

  setPaymentProcess(paymentInProcess) {
    if (paymentInProcess) {
      this.idsToShowOnPaymentProcessValue.forEach((id) => {
        document.getElementById(id).classList.remove("d-none")
      })
      this.idsToHideOnPaymentProcessValue.forEach((id) => {
        document.getElementById(id).classList.add("d-none")
      })
    } else {
      let div = document.getElementById('abortSessionBtn');
      if (div) {
        div.classList.remove('d-none');
      }
      this.idsToShowOnPaymentProcessValue.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.add("d-none");
        }
      });
      this.idsToHideOnPaymentProcessValue.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          element.classList.remove("d-none");
        }
      });
    }
  }
}
