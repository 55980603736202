import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"
import accounting from "accounting"

export default class extends Controller {
  static targets = ["incomeExpense", "incomePayments", "vat", "customFrom", "customTo", "customDate", "dateError", 'incomeSum', 'expenseSum']

  static values = {
    demoData: Boolean
  }

  connect() {
    this.renderIncomeExpenseChart()
    this.renderIncomePaymentsChart()
    this.renderVatChart()
  }

  disconnect() {
    this.incomeExpenseChart.destroy()
    this.incomePaymentsChart.destroy()
    this.vatChart.destroy()
  }

  renderIncomeExpenseChart(event) {
    if (this.hasIncomeExpenseTarget) {
      const url = this.incomeExpenseTarget.dataset.url
      let periodStart = this.incomeExpenseTarget.dataset.periodStart
      let periodEnd = this.incomeExpenseTarget.dataset.periodEnd

      if (event) {
        event.target.parentNode.querySelectorAll(".btn").forEach(a => a.classList.remove("active"))
        event.target.classList.add("active")
        periodStart = event.target.dataset.periodStart
        periodEnd = event.target.dataset.periodEnd
      }

      fetch(`${url}?period_start=${periodStart}&period_end=${periodEnd}&demo_data=${this.demoDataValue}`)
        .then((response) => response.json())
        .then((data) => {
          const options = {
            series: data.series,
            chart: {
              type: "bar",
              height: 440
            },
            colors: ["#44BA6B", "#FE5738"],
            xaxis: data.xaxis,
            tooltip: {
              enabled: true
            },
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                borderRadius: 3,
                borderRadiusApplication: "end"
              }
            },
            yaxis: {
              labels: {
                formatter: (value) => {
                  const formatted = accounting.formatMoney(value, "€", 0, ".", ",")
                  return formatted
                }
              }
            }
          }

          if (Math.max(...data.series.map(a => a.data.length)) == 0) {
            this.incomeExpenseTarget.innerHTML = "<div class='fw-bold text-center'>No Data Available</div>"
          } else {
            if (this.incomeExpenseChart) {
              this.incomeExpenseChart.updateOptions(options, true)
            } else {
              this.incomeExpenseChart = new ApexCharts(this.incomeExpenseTarget, options)
              this.incomeExpenseChart.render()
            }
            this.incomeExpenseTarget.insertAdjacentHTML("beforeend",
              `<div class="text-center">${data.dates.period_start} - ${data.dates.period_end}</div>`)
          }
          ['income', 'expense'].forEach((k) => {
            var dataset = data.series.find((s) => s.key === k);
            if (dataset) {
              var sum = dataset.data.reduce((a, b) => a + b, 0);
              this[`${k}SumTarget`].textContent = accounting.formatMoney(sum, "€", 0, ".", ",");
            }
          });

        })
    }
  }

  renderIncomePaymentsChart(event) {
    if (this.hasIncomePaymentsTarget) {
      const url = this.incomePaymentsTarget.dataset.url
      let periodStart = this.incomePaymentsTarget.dataset.periodStart
      let periodEnd = this.incomePaymentsTarget.dataset.periodEnd

      if (event) {
        event.target.parentNode.querySelectorAll(".btn").forEach(a => a.classList.remove("active"))
        event.target.classList.add("active")
        periodStart = event.target.dataset.periodStart
        periodEnd = event.target.dataset.periodEnd
      }

      fetch(`${url}?period_start=${periodStart}&period_end=${periodEnd}&demo_data=${this.demoDataValue}`)
        .then((response) => response.json())
        .then((data) => {
          const options = {
            series: data.series,
            chart: {
              type: "donut",
              height: 150
            },
            dataLabels: {
              enabled: false
            },
            colors: ["#44BA6B", "#FE5738"],
            labels: data.labels,
            tooltip: {
              enabled: true,
              y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                  return ""
                }
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }

          if (Math.max(...data.series) == 0) {
            this.incomePaymentsTarget.innerHTML = "<div class='fw-bold text-center'>No Data Available</div>"
          } else {
            if (this.incomePaymentsChart) {
              this.incomePaymentsChart.updateOptions(options, true)
            } else {
              this.incomePaymentsChart = new ApexCharts(this.incomePaymentsTarget, options)
              this.incomePaymentsChart.render()
            }
            this.incomePaymentsTarget.insertAdjacentHTML("beforeend",
              `<div class="text-center">${data.dates.period_start} - ${data.dates.period_end}</div>`)
          }
        })
    }
  }

  renderVatChart(event) {
    let demoData = this.element.dataset.demoData === 'true'
    if (this.hasVatTarget) {
      const url = this.vatTarget.dataset.url
      let periodStart = this.vatTarget.dataset.periodStart
      let periodEnd = this.vatTarget.dataset.periodEnd

      if (event) {
        event.target.parentNode.querySelectorAll(".btn").forEach(a => a.classList.remove("active"))
        event.target.classList.add("active")
        periodStart = event.target.dataset.periodStart
        periodEnd = event.target.dataset.periodEnd
      }

      fetch(`${url}?period_start=${periodStart}&period_end=${periodEnd}&demo_data=${this.demoDataValue}`)
        .then((response) => response.json())
        .then((data) => {
          data.xaxis["labels"] = {
            formatter: function (value, timestamp, opts) {
              const formatted = accounting.formatMoney(value, "€", 0, ".", ",")
              return formatted
            }
          }
          const options = {
            series: data.series,
            chart: {
              type: "bar",
              height: 150
            },
            plotOptions: {
              bar: {
                horizontal: true,
                borderRadius: 3,
                borderRadiusApplication: "end"
              }
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true,
              y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                  const formatted = accounting.formatMoney(value, "€", 2, ".", ",")
                  return formatted
                }
              }
            },
            xaxis: data.xaxis,
            colors: ["#44BA6B", "#FE5738"]
          }

          if (Math.max(...data.series.map(a => Math.max(...a.data))) == 0) {
            this.vatTarget.innerHTML = "<div class='fw-bold text-center'>No Data Available</div>"
          } else {
            if (this.vatChart) {
              this.vatChart.updateOptions(options, true)
            } else {
              this.vatChart = new ApexCharts(this.vatTarget, options)
              this.vatChart.render()
            }
            this.vatTarget.insertAdjacentHTML("beforeend",
              `<div class="text-center">${data.dates.period_start} - ${data.dates.period_end}</div>`)
          }
        })

    }
  }

  updateCustomDates() {
    const modal = bootstrap.Modal.getInstance(document.getElementById("dates-select"))
    const periodStart = this.customFromTarget.value.split("-").reverse().join("-")
    const periodEnd = this.customToTarget.value.split("-").reverse().join("-")
    const dateFrom = Date.parse(periodStart)
    const dateTo = Date.parse(periodEnd)
    this.dateErrorTarget.hidden = true

    if (this.customFromTarget.value == "" || this.customToTarget.value == "") {
      return false
    } else if (dateFrom <= dateTo) {
      this.customDateTargets.map(el => {
        el.dataset.periodStart = periodStart
        el.dataset.periodEnd = periodEnd
        if (el.dataset.trigger == this.modalTrigger) {
          el.dispatchEvent(new Event("updated"))
        }
      })
      modal.hide()
    } else {
      this.dateErrorTarget.hidden = false
    }
  }

  setTriggedBy(event) {
    this.modalTrigger = event.target.dataset.trigger
  }

}
